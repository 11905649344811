
import Vue from "vue";

import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import { format } from "date-fns";
import { Notification } from "@/lib/models";

export default Vue.extend({
  components: { InfiniteLoading },
  data() {
    return {
      notifications: [] as Notification[],
      lastKey: null as string | null,
    };
  },
  computed: {
    notificationList() {
      return this.$store.getters["notificationModule/notificationList"];
    },
  },
  methods: {
    dateFormat(date: string) {
      return format(date, "YYYY/MM/DD HH:mm");
    },
    async readNotifications(notifications: Notification[]) {
      const unreadNotificationIds = notifications.filter(
        (n) => !n.isRead
      ).map((n) => n.notificationId);
      if (unreadNotificationIds.length === 0) {
        return;
      }

      await this.$store.dispatch("notificationModule/readNotifications", {
        notificationGroup: "agent",
        notificationIds: unreadNotificationIds,
      });
    },
    async handleInfinite($state: StateChanger) {
      const result = await this.fetchNotifications();
      if (result.items.length > 0) {
        $state.loaded();
      }
      if (!result.lastKey) {
        $state.complete();
      }
    },
    async fetchNotifications() {
      const notifications = await this.$store.dispatch("notificationModule/listNotifications", {
        notificationGroup: "agent",
        limit: 50,
        lastKey: this.lastKey,
      });
      this.lastKey = notifications.lastKey;

      this.readNotifications(notifications.items);

      this.notifications.push(...notifications.items);
      return notifications;
    },
  },
});
